import { useCallback, useState } from 'react'
import { VisuallyHidden } from '@overdose/components'
import { IconShoppingBag } from '@tabler/icons-react'
import { Button } from '~/components/Button/'
import { HintTooltip } from '~/components/HintTooltip'
import { AddToCartButtonProps } from '~/components/ProductCard/ProductCard.types'
import { useCart, useCartDrawer } from '~/hooks'
import { useAlgoliaSearchInsignts } from '~/hooks/useAlgoliaSearchInsignts'
import { GTM, GTMEvent } from '~/lib'

export const AddToCartButton = ({
  sku,
  inStock,
  queryId,
  isButtonOnly = true,
  externalUrl,
  tooltipPosition = 'bottom',
}: AddToCartButtonProps) => {
  const { addToCart } = useCart()
  const { openCartDrawer } = useCartDrawer()
  const { sendAddToCartEvent } = useAlgoliaSearchInsignts()
  const [productQuantity, setProductQuantity] = useState<number>(1)

  const handleAddToCart = useCallback(() => {
    addToCart.mutate(
      [{ sku, quantity: productQuantity || 1, algoliaQueryId: queryId }],
      {
        onSuccess(data) {
          if (data) {
            GTM.dispatch(GTMEvent.CLEAR_ECOMMERCE)
            GTM.dispatch(GTMEvent.CART_CONTENTS_PUSH, data)
            sendAddToCartEvent([sku], data.lineItems, data.subTotal, queryId)
          }
          openCartDrawer()
        },
      }
    )
  }, [
    sku,
    productQuantity,
    queryId,
    addToCart,
    openCartDrawer,
    sendAddToCartEvent,
  ])

  const renderStandaloneButton = () => {
    const text = externalUrl ? 'Buy online' : 'Add to cart'

    return (
      <HintTooltip message={text} position={tooltipPosition}>
        <Button
          loading={addToCart.isLoading}
          data-id='addToCartButton'
          size='sm'
          icon={<IconShoppingBag />}
          iconOnly
          shape='circle'
          href={externalUrl}
          onClick={() => {
            handleAddToCart()
          }}
          data-testid='add-to-cart-button'
          disabled={!inStock}
          className='overflow-hidden'>
          <VisuallyHidden>{text}</VisuallyHidden>
        </Button>
      </HintTooltip>
    )
  }

  const renderButton = () => {
    if (isButtonOnly || externalUrl) {
      return renderStandaloneButton()
    }

    return (
      <Button
        data-id='addToCartButton'
        status='primary'
        size='xs'
        theme={{ root: 'basis-3/4 md:basis-full flex-1 px-2 normal-case' }}
        onClick={() => {
          handleAddToCart()

          if (productQuantity === 0) {
            setProductQuantity(1)
          }
        }}
        loading={addToCart.isLoading}
        disabled={!inStock}>
        {inStock ? 'Add to Cart' : 'Sold Out'}
      </Button>
    )
  }

  return <>{renderButton()}</>
}
